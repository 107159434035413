@font-face {
    font-family: 'Univers Light';
    font-style: normal;
    font-display: swap;
    src: local('Univers Light'), url('../assets/fonts/UniversLight.ttf') format('truetype');
}
@font-face {
    font-family: 'Univers';
    font-style: normal;
    font-display: swap;
    src: local('Univers'), url('../assets/fonts/UniversRegular.ttf') format('truetype');
}
@font-face {
    font-family: 'Univers Bold';
    font-style: normal;
    font-display: swap;
    src: local('Univers Bold'), url('../assets/fonts/UniversBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Univers Condensed';
    font-style: normal;
    font-display: swap;
    src: local('Univers'), url('../assets/fonts/UniversCnRg.ttf') format('truetype');
}
@font-face {
    font-family: 'Univers Condensed Bold';
    font-style: normal;
    font-display: swap;
    src: local('Univers'), url('../assets/fonts/UniversCnBold.ttf') format('truetype');
}
